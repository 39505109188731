import { getSession as getClientSession } from 'next-auth/react';

export async function getSession() {
  const isClient = typeof window !== 'undefined';
  if (isClient) {
    return await getClientSession();
  } else {
    const { getServerSession } = await import('next-auth/next');
    const { authOptions } = await import('@/app/api/auth/[...nextauth]/route');
    return await getServerSession(authOptions);
  }
}
