'use client';

import Script from 'next/script';
import { getSession } from '@/lib/nextAuth';

function TagManager() {
  // GTMにユーザーデータを入れる
  const setUserId = async () => {
    try {
      const session = await getSession();
      if (!session?.user) return;
      (window as any).dataLayer = (window as any).dataLayer || [];
      const dataLayer = (window as any).dataLayer;
      dataLayer.push({
        user_id: session?.user.id,
      });
    } catch (err) {
      // pass
    }
  };

  return (
    <>
      {process.env.NEXT_PUBLIC_GTM_ID && (
        <Script
          id='gtm'
          strategy='afterInteractive'
          dangerouslySetInnerHTML={{
            __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GTM_ID}');`,
          }}
          onReady={() => {
            setUserId();
          }}
        />
      )}
    </>
  );
}

export default TagManager;
