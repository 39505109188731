'use client';

import { Provider } from 'jotai';
import { SessionProvider } from 'next-auth/react';

export const JotaiProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => <Provider>{children}</Provider>;

export const AuthProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => <SessionProvider>{children}</SessionProvider>;
